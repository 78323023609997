.logo {
  width: 150px;
  height: 150px;
}

.g-logo img {
  width: 191px;
}

.a-logo img {
  width: 187px;
  height: 44px;
}

.pet-pic {
  width: 100%;
  height: 190px;
}

.weight-card {
  width: 100%;
  min-height: 220px;
}

ion-item.pet-item {
  --min-height: 20px;
}

ion-card.banner {
  color: black;
  height: auto;
  background: rgb(240, 234, 227);
  background: linear-gradient(
    90deg,
    rgba(240, 234, 227, 1) 0%,
    rgba(238, 210, 186, 1) 60%
  );
  cursor: pointer;
}

ion-card.home-banner {
  color: black;
  height: auto;
  background: rgb(238, 210, 186);
  background: linear-gradient(
    90deg,
    rgba(238, 210, 186, 1) 0%,
    rgba(240, 234, 227, 1) 60%
  );
}

ion-button.banner-btn {
  --border-color: white;
  --border-width: 1px;
  --border-radius: 10px;
  text-align: right;
  text-transform: capitalize;
}

.icon-home {
  color: var(--ion-color-primary);
}

.home-vaccine {
  color: var(--ion-color-primary);
}

.icon-home svg {
  font-size: 4rem;
}

.slick-dots {
  position: absolute;
  bottom: 100px;
}

.slick-dots li.slick-active button:before {
  color: var(--ion-color-primary);
}

span.pic-edit {
  position: absolute;
  right: 16px;
  bottom: 0;
  padding: 8px;
  z-index: 10;
  color: gray;
  font-size: 0.875rem;
  background: rgb(178 163 163 / 10%);
}

.login-sep {
  color: var(--ion-color-primary);
}

.b-secondary {
  border-color: var(--ion-color-secondary);
}

.b-primary {
  border: var(--ion-color-primary);
}

.b-tertiary {
  border: var(--ion-color-tertiary);
}

@media screen and (min-width: 60em) {
  .reg-box {
    width: 450px !important;
  }
  .log-box {
    width: 450px !important;
  }
  .modal-box {
    width: 450px !important;
  }
}

.modal-box {
  padding-bottom: 70px;
}

ion-button.no-account {
  --padding-start: 0;
  --padding-end: 0;
}

ion-button.fg-pass {
  --padding-start: 0;
  --padding-end: 0;
}

ion-item.vaccine {
  --padding-end: 0;
}

ion-button {
  --border-width: 1px;
  --border-radius: 10px;
  text-align: right;
  text-transform: capitalize;
}

ion-list-header.ios {
  font-size: 18px;
  padding-bottom: 8px;
}

ion-card-content .notes {
  text-overflow: ellipsis;
  overflow: hidden;
  height: 1.2em;
  white-space: nowrap;
}

.picker-opt {
  font-size: 16px;
}

.weight-btn {
  bottom: 5px;
  left: 24px;
}

.label {
  margin: 0;
}

.item-inner {
  padding-right: 0px !important;
}

.bg-secondary {
  background-color: var(--ion-color-secondary);
}

.bg-primary {
  background-color: var(--ion-color-primary);
}

.bg-tertiary {
  background-color: var(--ion-color-tertiary);
}

ion-item.expense-item {
  --inner-padding-end: 0px;
}

ion-item.weight-item {
  --inner-padding-end: 0px;
}

.profile-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
  margin-left: auto;
  margin-right: auto;
}

.alert-radio-group.sc-ion-alert-md,
.alert-checkbox-group.sc-ion-alert-md {
  max-height: 265px;
}

ion-toast.notify-toast {
  --background: white;
  --color: black;
}

ion-toast.notify-toast .rem-title {
  font-weight: bold;
}

h5.notify {
  color: black;
}

ion-avatar.pet-avatar {
  height: 190px;
  width: 190px;
}

/* IOS Specific styles */

ion-button[type="button"] {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
}

ion-button[type="submit"] {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
}

.ios ion-title {
  position: relative;
}

ion-card.ios {
  margin-top: 6px;
  margin-bottom: 6px;
}

ion-card.ios.home-card {
  margin-top: 12px;
  margin-bottom: 12px;
}

ion-card.ios.home-alerts {
  margin-top: 12px;
  margin-bottom: 12px;
}

picture.home-picture {
  width: 100%;
}

.smartbanner-android {
  box-shadow: inset 0 4px 0 #a63c00 !important;
}

.smartbanner.smartbanner-android.smartbanner-top {
  position: fixed;
  top: 64px;
  width: 100%;
  z-index: 10;
}

.smartbanner-show.smartbanner-margin-top {
  margin-top: 0;
}

.smartbanner-android .smartbanner-button-text {
  background: var(--ion-color-primary) !important;
  color: white;
}

.smartbanner-android .smartbanner-close {
  background: white !important;
  color: var(--ion-color-primary) !important;
}

.smartbanner-android .smartbanner-button {
  margin-right: 20px;
}

ion-content.ion-wrapo {
  --padding-bottom: 60px;
}

ion-searchbar.md {
  padding: 10px !important;
}

ion-searchbar.ios {
  padding: 16px !important;
  padding-bottom: 0 !important ;
}

ion-label.exp-meta {
  font-size: 0.65rem !important;
  padding-top: 3px;
  opacity: 0.8;
}

ion-label.sub-title {
  font-size: 0.65rem !important;
  opacity: 0.8;
  margin-bottom: 0;
}

.sub-title {
  font-size: 0.65rem !important;
  line-height: 15px !important;
}

.required:after {
  content: " *";
  color: red;
}

ion-segment.ios {
  margin-right: 16px;
  margin-left: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}

ion-badge.home-time {
  margin-left: 10px;
  padding-left: 5px;
  font-weight: normal;
  opacity: 0.7;
  background: none;
}

ion-item.home-item:last-child {
  --inner-border-width: 0;
}

.no-account.ios {
  padding-left: 1rem;
}

.fg-pass.ios {
  padding-left: 1rem;
}

.app-logo img {
  height: 40px;
  width: auto;
  padding-left: 16px;
}

form ion-item {
  --padding-start: 0;
}

.pet-form-error {
  color: #e7040f;
  padding-left: 0 !important;
}

.pet-form ion-item {
  --padding-start: 0;
}

ion-avatar.doc-avatar {
  --border-radius: 0;
  width: 2rem;
  height: 2rem;
}

.doc-btn span {
  text-transform: capitalize !important;
  justify-content: start !important;
  padding-left: 6px !important;
}

.doc-alert .alert-wrapper .alert-button-group {
  display: block !important;
}

.doc-btn {
  width: 100%;
}

ion-slides {
  height: 100%;
}

.swiper-slide img {
  max-height: 20%;
  max-width: 30%;
  margin: 0;
  pointer-events: none;
}

.swiper-slide h2 {
  margin-top: 2.8rem;
  font-size: 20px;
  opacity: 0.8;
}

ion-img.pet-icons {
  overflow: visible;
  height: 150px;
  width: 150px;
}

.pets-page-icon {
  max-width: 100%;
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

ion-button.started-btn {
  --border-radius: 2px;
  width: 187px;
  height: 40px;
}

ion-card.home-alerts {
  color: black;
  height: auto;
  background: rgb(238, 210, 186);
  background: linear-gradient(
    90deg,
    rgba(238, 210, 186, 1) 0%,
    rgba(240, 234, 227, 1) 60%
  );
}

.ripple-none {
  --ripple-color: transparent;
}

.min-height-mocard {
  min-height: 100px !important;
  background-color: whitesmoke;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

.home-margin ion-tabs{
  padding-bottom: 60px!important;
}

form ion-item {
  --border-width: 0;
  --inner-border-width: 0;
  --show-inset-highlight:0; 
  --highlight-height:0; 
}

form ion-input,
form ion-select,
form ion-datetime,
form ion-textarea {
  border: 1px solid #DFE5EE;
  border-radius:8px;
  margin-top:5px;
  --padding-start:5px !important;
}
.item-inner {
  padding-right: 0;
}
form ion-button {
  margin-right: 1rem;
}